<template>
  <div>
    <MyToolbar />
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="beginDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="beginDate"
              label="日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="beginDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="beginDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="endDateMenu = false"
          />
        </v-menu>
      </v-flex>

      <v-flex xs3 class="pa-2">
        <v-switch
          label="設定したまるめ時間で集計する"
          v-model="isShowRounding"
          color="orange"
        ></v-switch>
      </v-flex>

      <v-spacer />

      <v-btn
        v-if="companyItem.is_multiple_attendance === 1"
        color="primary"
        dark
        class="ma-2"
        @click="$router.push('/manager/project-aggregate')"
      >
        作業別集計データ
      </v-btn>
      <v-btn
        color="primary"
        dark
        class="ma-2"
        @click="$router.push('/manager/closed-monthly-data')"
      >
        締め済み月次データ
      </v-btn>
      <v-btn
        color="primary"
        dark
        class="ma-2"
        @click.stop="open()"
      >
        集計データの締め処理
      </v-btn>

      <v-dialog
        v-model="dialog"
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline">集計データ締め処理</v-card-title>

          <v-card-text>
            <v-layout row wrap align-center>
              <v-flex xs6>
                <v-subheader class="title">日付範囲</v-subheader>
              </v-flex>
              <v-flex xs6 class="subheading">
                {{ beginDate }}~{{ endDate }}
              </v-flex>
              <v-flex xs6>
                <v-subheader class="title">月次単位のまるめ設定</v-subheader>
              </v-flex>
              <v-flex xs6 class="subheading">
                {{ isShowRounding === true ? '設定する' : '設定しない' }}
              </v-flex>
              <v-flex xs6>
                <v-subheader class="title">登録年月度</v-subheader>
              </v-flex>
              <v-flex xs2 class="subheading">
                <v-select
                  :items="yearItems"
                  v-model="selectYear"
                  suffix="年"
                  @change="$v.selectYear.$touch()"
                  @blur="$v.selectYear.$touch()"
                  :error-messages="selectYearErrors"
                ></v-select>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs2 class="subheading">
                <v-select
                  suffix="月"
                  v-model="selectMonth"
                  :items="monthItems"
                  @change="$v.selectMonth.$touch()"
                  @blur="$v.selectMonth.$touch()"
                  :error-messages="selectMonthErrors"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="grey lighten-1" flat="flat" @click="cancel()">
              キャンセル
            </v-btn>
            <v-btn color="orange" flat="flat" @click="save()">
              締め処理をする
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="roundTimeDialog" scrollable max-width="550px">
        <template v-slot:activator="{ on }">
          <v-btn class="ma-2" color="primary" dark v-on="on">まるめ設定</v-btn>
        </template>

        <v-card>
          <v-card-title>
            <p style="font-weight: bold; font-size: 1.2em">集計データのまるめ設定</p>
            <span style="color: red">
              下記の項目を労基法に準拠（30分未満を切り捨て、30分以上を切り上げ）する
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 400px;">
            <v-checkbox v-model="overtimeWorkdayMinutes" label="時間外" />
            <v-checkbox v-model="overtimeWorkdayMidnightMinutes" label="深夜時間外" />
            <v-checkbox v-model="legalOvertimeWorkingMinutes" label="法定時間内" />
            <v-checkbox v-model="nonStatutoryWorkingMinutes" label="法定時間外" />
            <v-checkbox v-model="holidayWorkMinutes" label="休日労働時間" />
            <v-checkbox
              v-model="overtimeHolidayWorkdayMidnightMinutes"
              label="休日深夜時間外"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="grey lighten-1" flat @click="roundTimeDialog = false">
              Cancel
            </v-btn>
            <v-btn color="orange" flat @click="registerRoundingSetting('set')">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-layout>
      <v-flex xs2 class="pl-2 pr-2 pb-2">
        <v-btn color="success" @click="getWorktimeRecord()">日付範囲で集計する</v-btn>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId" />
      </v-flex>
      <v-flex xs3 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired" />
      </v-flex>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title
          class="pl-3"
          style="font-weight: bold; border-left: solid 5px #456ab8;"
        >
          集計データ
        </v-toolbar-title>
      </v-toolbar>

      <v-layout>
        <v-spacer />
        <v-toolbar dense flat class="mb-2" style="width: 40em;">
          <v-toolbar-title class="mr-5" style="width: 5em;">CSV</v-toolbar-title>
          <v-overflow-btn
            :items="['UTF-8', 'Shift_JIS']"
            label=""
            v-model="csvEncode"
            hide-details
            class="pa-0"
          ></v-overflow-btn>
          <v-btn icon @click="tableToCSV"><v-icon>cloud_download</v-icon></v-btn>
          <v-divider vertical />
          <v-btn icon @click="printTable"><v-icon>print</v-icon></v-btn>
        </v-toolbar>
      </v-layout>

      <div style="display: block">
        <v-layout row wrap class="timetable automatic-aggregate-header">
          <v-flex
            xs2
            class="pa-3"
            style="font-weight: bold; padding-top: 2em !important" text-xs-center
          >
            氏名
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                出勤<br>日数
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                休日<br>出勤<br>日数
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                遅刻<br>回数
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                早退<br>回数
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                有休<br>日数
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                遅刻<br>時間
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                早退<br>時間
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                休憩<br>時間
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 2em !important"
              >
                時間外
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                深夜<br>時間外
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                法定<br>時間内
              </v-flex>
              <v-flex
                xs1
                class="pa-2 automatic-aggregate-th"
                style="padding-top: 1.3em !important"
              >
                法定<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                休日<br>労働<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                休日<br>深夜<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                労働<br>時間<br>合計
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
        </v-layout>

        <v-progress-linear v-if="isWait == 1" :indeterminate="true" />
        <v-alert
          v-else
          :value="!Object.keys(automatic).length"
          color="error"
          icon="warning"
          outline
        >
          データがありません
        </v-alert>
      </div>

      <div class="tbody" style="font-size: 15px">
        <v-layout
          v-for="(item, idx) in automatic"
          :key="item.date"
          row
          wrap
          class="timetable"
          :class="item.class_name === 'whiteCursor' ? 'whiteCursor' : 'grayCursor'"
        >
          <v-flex
            xs2
            class="pa-2 automatic-aggregate-td"
            v-on:mouseover="mouseover(idx)"
            v-on:mouseleave="mouseleave"
            :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
          >
            <span class="subheading">{{ item.name }}</span>
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <!-- 出勤日数 -->
              <v-flex
                xs1
                :key="`name-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.workTime }}
              </v-flex>
              <!-- 出勤日数 -->

              <!-- 休日出勤日数 -->
              <v-flex
                xs1
                :key="`name2-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.holidayWorkTime }}
              </v-flex>
              <!-- 休日出勤日数 -->

              <!-- 遅刻回数 -->
              <v-flex
                xs1
                :key="`attendance-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.is_tardiness }}
              </v-flex>
              <!-- 遅刻回数 -->

              <!-- 早退回数 -->
              <v-flex
                xs1
                :key="`leave-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.is_early_withdrawal }}
              </v-flex>
              <!-- 早退回数 -->

              <v-flex
                xs1
                :key="`restHours-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.leaveApplication }}
              </v-flex>
              <v-flex
                xs1
                :key="`workHours-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.tardiness_time) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.early_withdrawal_time) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours2-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.rest_minutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours300-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.overtime_workday_minutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours4-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.overtime_workday_midnight_minutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours3-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.legal_overtime_working_minutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours30-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.non_statutory_working_minutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours5-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.holidayWorkMinutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours6-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.overtime_holiday_workday_midnight_minutes) }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours7-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ changeNotationToHourTime(item.work_minute)  }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="idx !== processedItems.length - 1" xs12>
            <v-divider />
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import Encoding from 'encoding-japanese'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import { saveAs } from 'file-saver'
import itemTools from '@/mixins/item_tools.js'
import Papa from 'papaparse'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CsvDownload',
  mixins: [validationMixin, itemTools],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  validations: {
    selectYear: { required },
    selectMonth: { required }
  },
  data: () => ({
    isRetired: 0,
    retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }, { id: 3, name: '指定なし' }],
    isWait: 1,
    projectItems: [],
    csvEncode: 'UTF-8',
    groupId: 0,
    group: '指定なし',
    selectYear: '',
    selectMonth: '',
    monthItems: [],
    yearItems: [],
    dialog: false,
    overtimeWorkdayMinutes: false,
    overtimeWorkdayMidnightMinutes: false,
    legalOvertimeWorkingMinutes: false,
    nonStatutoryWorkingMinutes: false,
    holidayWorkMinutes: false,
    overtimeHolidayWorkdayMidnightMinutes: false,
    isShowRounding: false,
    roundTimeDialog: false,
    bg: '',
    companyItem: {},
    automatic: [],
    leaveApplicationDataItems2: [],
    automaticAggregateItems: [],
    leaveApplicationDataItems: {
      paid_leave: '有給休暇',
      am_paid_leave: '午前有給休暇',
      pm_paid_leave: '午後有給休暇',
      time_paid_leave: '時間有給',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇',
      general_absence: '一般欠勤',
      disease_scratch_absence: '病傷欠勤',
      closing: '休業',
      time_closing: '時間休業',
      public_holiday: '公休日'
    },
    beginDateMenu2: false,
    endDateMenu2: false,
    csvOutputDialog: false,
    csvBeginDate: moment().date(16).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    csvEndDate: moment().add(1, 'months').date(15).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    today: moment().tz('Asia/Tokyo').format('YYYY-MM-DD'),
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    headers: [
      { text: '氏名', value: 'name', sortable: false },
      { text: '出勤', value: 'name', sortable: false },
      { text: '退勤', value: 'name', sortable: false },
      { text: '休憩', value: 'name', sortable: false },
      { text: '労働時間', value: 'name', sortable: false }
    ],
    worktimeRecordItems: []
  }),
  computed: {
    selectYearErrors () {
      const errors = []
      if (!this.$v.selectYear.$dirty) return errors
      !this.$v.selectYear.required && errors.push('登録年は必須です')
      return errors
    },
    selectMonthErrors () {
      const errors = []
      if (!this.$v.selectMonth.$dirty) return errors
      !this.$v.selectMonth.required && errors.push('登録月は必須です')
      return errors
    }
  },
  watch: {
    isShowRounding () {
      if (this.isShowRounding) {
        localStorage.setItem('isShowRounding', 'true')
      } else {
        localStorage.setItem('isShowRounding', 'false')
      }

      this.getWorktimeRecord()
    },
    automaticAggregateItems () {
      let obj = {}
      if (localStorage.getItem('isShowRounding') !== null) {
        let getjson = localStorage.getItem('isShowRounding')
        this.isShowRounding = JSON.parse(getjson)
      }

      if (this.isShowRounding && localStorage.getItem('roundingSettingObj') !== null) {
        let getjson2 = localStorage.getItem('roundingSettingObj')
        obj = JSON.parse(getjson2)

        this.overtimeWorkdayMinutes = obj['overtime_workday_minutes']
        this.overtimeWorkdayMidnightMinutes = obj['overtime_workday_midnight_minutes']
        this.legalOvertimeWorkingMinutes = obj['legal_overtime_working_minutes']
        this.nonStatutoryWorkingMinutes = obj['non_statutory_working_minutes']
        this.holidayWorkMinutes = obj['holidayWorkMinutes']
        this.overtimeHolidayWorkdayMidnightMinutes = obj['overtime_holiday_workday_midnight_minutes']
      }
      this.automatic = this.automaticprocessItems(this.automaticAggregateItems, this.leaveApplicationDataItems2, this.isShowRounding, obj, this.staffItems, this.companyItem)
      this.isWait = 0
    },
    staff () {
      this.getWorktimeRecord()
    },
    group () {
      this.getWorktimeRecord()
    },
    isRetired () {
      this.getWorktimeRecord()
    }
  },
  mounted () {
    this.getStaff()
    this.getProject()
    this.registerRoundingSetting('get')
    this.yearArrayCreation()
    this.monthArrayCreation()

    let loaded = false
    const scriptTags = Array.from(document.getElementsByTagName('script'))
    scriptTags.forEach((tag) => {
      if (/pdfmake/.test(tag.src)) {
        loaded = true
      }
    })
    if (loaded === false) {
      let pdfMakeScript = document.createElement('script')
      pdfMakeScript.src = '/vue/pdfmake/pdfmake.min.js'
      document.body.appendChild(pdfMakeScript)
      let vfsFontsScript = document.createElement('script')
      vfsFontsScript.src = '/vue/pdfmake/vfs_fonts.js'
      document.body.appendChild(vfsFontsScript)
    }
  },
  methods: {
    getStaff () {
      axios.get('/api/manager/staff')
        .then((res) => {
          this.staffItems = res.data
          this.getCompany()
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getStaff]')
        })
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.projectItems = []
          this.projectItems = res.data
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    changeNotationToHourTime: function (minute) {
      if (minute === 'leave-application') {
        return ''
      } else if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    },
    open () {
      this.$v.$reset()
      this.dialog = true
    },
    cancel () {
      this.$v.$reset()
      this.selectYear = ''
      this.selectMonth = ''
      this.dialog = false
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let editedItem = {
          data_by_staff: this.automatic,
          begin_date: this.beginDate,
          end_date: this.endDate,
          closed_date: this.today,
          year_month: this.selectYear + '-' + this.selectMonth,
          is_round_monthly: this.isShowRounding ? 1 : 0,
          round_overtime_workday_minutes: this.overtimeWorkdayMinutes ? 1 : 0,
          round_overtime_workday_midnight_minutes: this.overtimeWorkdayMidnightMinutes ? 1 : 0,
          round_legal_overtime_working_minutes: this.legalOvertimeWorkingMinutes ? 1 : 0,
          round_non_statutory_working_minutes: this.nonStatutoryWorkingMinutes ? 1 : 0,
          round_holiday_work_minutes: this.holidayWorkMinutes ? 1 : 0,
          round_overtime_holiday_workday_midnight_minutes: this.overtimeHolidayWorkdayMidnightMinutes ? 1 : 0
        }
        axios.post('/api/manager/close-monthly', editedItem)
          .then((res) => {
            this.selectYear = ''
            this.selectMonth = ''
            this.dialog = false
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    monthArrayCreation () {
      for (let i = 1; i <= 12; i++) {
        this.monthItems.push(String(i))
      }
    },
    yearArrayCreation () {
      let currentYear = moment().year()
      for (let i = currentYear - 3; i <= currentYear; i++) {
        this.yearItems.push(String(i))
      }
    },
    registerRoundingSetting (flag) {
      let obj = {
        'overtime_workday_minutes': this.overtimeWorkdayMinutes,
        'overtime_workday_midnight_minutes': this.overtimeWorkdayMidnightMinutes,
        'legal_overtime_working_minutes': this.legalOvertimeWorkingMinutes,
        'non_statutory_working_minutes': this.nonStatutoryWorkingMinutes,
        'holidayWorkMinutes': this.holidayWorkMinutes,
        'overtime_holiday_workday_midnight_minutes': this.overtimeHolidayWorkdayMidnightMinutes
      }

      if (flag === 'set') {
        let setjson = JSON.stringify(obj)
        localStorage.setItem('roundingSettingObj', setjson)
      } else {
        if (localStorage.getItem('roundingSettingObj') !== null) {
          let getjson = localStorage.getItem('roundingSettingObj')
          let obj2 = JSON.parse(getjson)
          this.overtimeWorkdayMinutes = obj2['overtime_workday_minutes']
          this.overtimeWorkdayMidnightMinutes = obj2['overtime_workday_midnight_minutes']
          this.legalOvertimeWorkingMinutes = obj2['legal_overtime_working_minutes']
          this.nonStatutoryWorkingMinutes = obj2['non_statutory_working_minutes']
          this.holidayWorkMinutes = obj2['holidayWorkMinutes']
          this.overtimeHolidayWorkdayMidnightMinutes = obj2['overtime_holiday_workday_midnight_minutes']
        }
      }

      this.roundTimeDialog = false
    },
    mouseleave () {
      this.bg = ''
    },
    mouseover (idx) {
      this.bg = idx
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
          this.getWorktimeRecord()
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    timeNotationChange (time, notation) {
      if (time === 0) {
        return 0
      } else {
        if (notation === 'hour') {
          if (parseInt((time * 60) % 60) === 0) {
            return Math.floor(time)
          } else {
            return Math.floor(time) + ' : ' + parseInt((time * 60) % 60)
          }
        } else {
          if (parseInt(time * 60) === 0) {
            return Math.floor(time)
          } else {
            return Math.floor(time / 60) + ' : ' + parseInt((time % 60))
          }
        }
      }
    },
    processedItems () {
      let pItems = this.processItems(this.worktimeRecordItems, this.staff, this.companyItem, '', this.projectItems)
      let pItems2 = []

      pItems.forEach((item) => {
        if (item.date >= this.beginDate) {
          pItems2.push(item)
        }
      })
      this.getLeaveApplication(pItems2)
    },
    getLeaveApplication (pItems2) {
      this.leaveApplicationDataItems2 = []
      axios.get('/api/manager/leave-application?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.leaveApplicationDataItems2.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.leaveApplicationDataItems2.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.leaveApplicationDataItems2.push(item)
              }
            }
          })
          this.automaticAggregateItems = pItems2
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })
    },
    convAtTime (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm')
    },
    downloadCSV () {
      let items2 = []

      axios.get('/api/manager/leave-application?begin_date=' + this.csvBeginDate + '&end_date=' + this.csvEndDate)
        .then((res) => {
          res.data.forEach((leaveApplicationData) => {
            if (leaveApplicationData.is_approved === 1) {
              let leaveApplicationDataObj = {
                '従業員ID': leaveApplicationData.yayoi_code,
                '日付': moment(leaveApplicationData.application_date).format('YYYY/MM/DD'),
                '出勤時刻': '',
                '退勤時刻': '',
                '普外時間': '',
                '残外時間': '',
                '事由': this.leaveApplicationDataItems[leaveApplicationData.category]
              }
              items2.push(leaveApplicationDataObj)
            }
          })
          axios.get('/api/manager/specified-worktime?begin_date=' + this.csvBeginDate + '&end_date=' + this.csvEndDate)
            .then((res) => {
              let items = this.processItems(res.data, '')

              items.forEach((dayObj) => {
                dayObj.staffItems.forEach((staffItem) => {
                  let staffObj = {
                    '従業員ID': staffItem.yayoi_code,
                    '日付': moment(dayObj.date).format('YYYY/MM/DD'),
                    '出勤時刻': staffItem.attendance,
                    '退勤時刻': staffItem.leave,
                    '普外時間': '00:00',
                    '残外時間': '00:00',
                    '事由': '設定なし'
                  }
                  items2.push(staffObj)
                })
              })
              const csvData = Papa.unparse(items2)
              const unicodeList = []
              for (let i = 0; i < csvData.length; i += 1) {
                unicodeList.push(csvData.charCodeAt(i))
              }
              const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode')
              const uInt8List = new Uint8Array(shiftJisCodeList)
              const writeData = new Blob([uInt8List], { type: 'text/csv' })
              saveAs(writeData, 'itc-attendance.csv')
              this.csvOutputDialog = false
            })
            .catch(() => {
              alert('データ取得エラー[csvOutput]')
            })
        })
        .catch(() => {
          alert('取得エラー')
        })
    },
    convAtDate (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getWorktimeRecord () {
      this.worktimeRecordItems = []
      this.isWait = 1
      // 週40時間を超えた場合の時間外の計算のために、日付指定（開始）より6日前から取得するようにする
      // 表示は日付指定（開始）より表示されるように制御する
      let beginDate = moment(this.beginDate).subtract(6, 'd').format('YYYY-MM-DD')

      axios.get('/api/manager/worktime-record?begin_date=' + beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          res.data.forEach((item) => {
            if (this.isRetired === 3) {
              this.worktimeRecordItems.push(item)
            } else if (this.isRetired === 0) {
              if (item.is_retired === 0) {
                this.worktimeRecordItems.push(item)
              }
            } else if (this.isRetired === 1) {
              if (item.is_retired === 1) {
                this.worktimeRecordItems.push(item)
              }
            }
          })
          this.processedItems()
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    },
    tableToCSV () {
      const items = [[
        '氏名',
        '出勤日数',
        '休日出勤日数',
        '遅刻回数',
        '早退回数',
        '有休日数',
        '遅刻時間',
        '早退時間',
        '休憩時間',
        '時間外',
        '深夜時間外',
        '法定時間内',
        '法定時間外',
        '休日労働時間',
        '休日深夜時間外',
        '労働時間合計'
      ]]
      Object.keys(this.automatic).forEach((key) => {
        let item = this.automatic[key]
        items.push([
          item.name,
          item.workTime,
          item.holidayWorkTime,
          item.is_tardiness,
          item.is_early_withdrawal,
          item.leaveApplication,
          this.changeNotationToHourTime(item.tardiness_time),
          this.changeNotationToHourTime(item.early_withdrawal_time),
          this.changeNotationToHourTime(item.rest_minutes),
          this.changeNotationToHourTime(item.overtime_workday_minutes),
          this.changeNotationToHourTime(item.overtime_workday_midnight_minutes),
          this.changeNotationToHourTime(item.legal_overtime_working_minutes),
          this.changeNotationToHourTime(item.non_statutory_working_minutes),
          this.changeNotationToHourTime(item.holidayWorkMinutes),
          this.changeNotationToHourTime(item.overtime_holiday_workday_midnight_minutes),
          this.changeNotationToHourTime(item.work_minute)
        ])
      })
      let csvData = Papa.unparse(items)
      if (this.csvEncode === 'Shift_JIS') {
        const unicodeList = []
        for (let i = 0; i < csvData.length; i += 1) {
          unicodeList.push(csvData.charCodeAt(i))
        }
        const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode')
        csvData = new Uint8Array(shiftJisCodeList)
      }
      const writeData = new Blob([csvData], { type: 'text/csv' })
      saveAs(writeData, 'atmp.csv')
    },
    printPDF: function (content, orientation) {
      window.pdfMake.fonts = {
        GenShin: {
          normal: 'GenShinGothic-P-Regular.ttf',
          bold: 'GenShinGothic-P-Regular.ttf',
          italics: 'GenShinGothic-P-Regular.ttf',
          bolditalics: 'GenShinGothic-P-Regular.ttf'
        }
      }
      window.pdfMake.createPdf({
        pageOrientation: orientation,
        content: content,
        styles: {
          th: {
            fillColor: '#eeeeee',
            alignment: 'left'
          },
          marginTop10: {
            margin: [0, 10, 0, 0]
          }
        },
        defaultStyle: {
          font: 'GenShin',
          fontSize: 8
        }
      }).open()
    },
    layoutTable () {
      return {
        hLineWidth: function () {
          return 0.5
        },
        vLineWidth: function () {
          return 0.5
        }
      }
    },
    printTable () {
      const items = []
      Object.keys(this.automatic).forEach((key) => {
        let item = this.automatic[key]
        items.push([
          { text: item.name, alignment: 'center' },
          item.workTime,
          item.holidayWorkTime,
          item.is_tardiness,
          item.is_early_withdrawal,
          item.leaveApplication,
          this.changeNotationToHourTime(item.tardiness_time),
          this.changeNotationToHourTime(item.early_withdrawal_time),
          this.changeNotationToHourTime(item.rest_minutes),
          this.changeNotationToHourTime(item.overtime_workday_minutes),
          this.changeNotationToHourTime(item.overtime_workday_midnight_minutes),
          this.changeNotationToHourTime(item.legal_overtime_working_minutes),
          this.changeNotationToHourTime(item.non_statutory_working_minutes),
          this.changeNotationToHourTime(item.holidayWorkMinutes),
          this.changeNotationToHourTime(item.overtime_holiday_workday_midnight_minutes),
          this.changeNotationToHourTime(item.work_minute)
        ])
      })
      const content = [
        {
          text: '集計データ',
          fontSize: 17,
          alignment: 'center'
        },
        {
          width: 'auto',
          fontSize: 14,
          alignment: 'left',
          table: {
            widths: ['auto', 'auto'],
            body: [
              ['会社名', localStorage.getItem('companyName')]
            ]
          }
        },
        {
          text: this.beginDate + ' 〜 ' + this.endDate,
          margin: [0, 10, 0, 0],
          fontSize: 13,
          alignment: 'right'
        },
        {
          style: 'marginTop10',
          layout: this.layoutTable(),
          fontSize: 11,
          alignment: 'center',
          table: {
            widths: [
              '*', 30, 30, 30, 30,
              30, 30, 30, 30, 35,
              35, 35, 35, 30, 35,
              'auto'
            ],
            body: [
              [
                { text: '氏名', style: 'th', alignment: 'center', margin: [0, 16] },
                { text: '出勤日数', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '休日出勤日数', style: 'th', alignment: 'center', margin: [0, 2] },
                { text: '遅刻回数', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '早退回数', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '有休日数', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '遅刻時間', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '早退時間', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '休憩時間', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '時間外', style: 'th', alignment: 'center', margin: [0, 16] },
                { text: ' 深夜 時間外', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: ' 法定 時間内', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: ' 法定 時間外', style: 'th', alignment: 'center', margin: [0, 10] },
                { text: '休日労働時間', style: 'th', alignment: 'center', margin: [0, 2] },
                { text: ' 休日  深夜 時間外 ', style: 'th', alignment: 'center', margin: [0, 2] },
                { text: '労働時間合計', style: 'th', alignment: 'center', margin: [0, 16] }
              ],
              ...items
            ]
          }
        }
      ]
      this.printPDF(content, 'landscape')
    }
  }
}
</script>

<style>
.automatic-aggregate-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
}
.automatic-aggregate-th {
  font-weight: bold;
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.automatic-aggregate-td {
  border-right: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>

<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.tbody::-webkit-scrollbar {
    display:none;
}
.grayCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.whiteCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
