<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items3" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="beginDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="beginDate2"
              label="日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="beginDate2"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="beginDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate2"
              label="日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate2"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="endDateMenu = false"
            ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId" />
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" />
      </v-flex>
    </v-layout>
    <v-flex xs2 class="pl-2 pr-2 pb-2">
      <v-btn color="success" @click="aggregate()">日付範囲で表示する</v-btn>
    </v-flex>

    <v-card class="mb-3 mx-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">
          葬儀用一覧データ/<span class="title">{{ staff }}</span>
        </v-toolbar-title>
        <v-spacer />
         <v-btn @click="printTable"><v-icon style="margin-right: 0.3em">print</v-icon>全スタッフ分を印刷</v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="processedItems"
        class="elevation-1"
        hide-actions
      >
        <template v-slot:no-data>
          <v-alert v-if="processedItems.length === 0" :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>

        <template v-slot:items="props">
          <td class="text-xs-center">{{ props.item.date }}</td>
          <td class="text-xs-center">{{ props.item.begin_time }}</td>
          <td class="text-xs-center">{{ props.item.end_time }}</td>
          <td class="text-xs-center">{{ props.item.non_work_time }}</td>
          <td class="text-xs-center">{{ props.item.wake_work_time }}</td>
          <td class="text-xs-center">
            <v-tooltip v-if="props.item.errorText" top>
              <template v-slot:activator="{ on }">
                <v-icon color="accent" v-on="on">warning</v-icon>
              </template>
              <span v-html="props.item.errorText"></span>
            </v-tooltip>
          </td>
          <td class="text-xs-center">{{ outputWorkingLocation(props.item.working_location) }}</td>
        </template>
      </v-data-table>

    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import itemTools from '@/mixins/item_tools.js'

export default {
  mixins: [itemTools],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  data: () => ({
    headers: [
      { text: '日付', align: 'center', value: 'date', sortable: false, width: '200', class: 'worktime-record-header' },
      { text: '開始', align: 'center', value: 'begin', sortable: false, class: 'worktime-record-header', width: 90 },
      { text: '終了', align: 'center', value: 'end', sortable: false, class: 'worktime-record-header', width: 90 },
      { text: '非作業', align: 'center', value: 'wake', sortable: false, class: 'worktime-record-header', width: 90 },
      { text: '通夜', align: 'center', value: 'wake', sortable: false, class: 'worktime-record-header', width: 90 },
      { text: '', align: 'center', value: 'alert', sortable: false, class: 'worktime-record-header', width: 50 },
      { text: '場所', align: 'center', value: 'place', sortable: false, width: '900', class: 'worktime-record-header' }
    ],
    worktimeItems: [],
    items3: [
      {
        text: '一覧データ',
        disabled: false,
        href: '/#/manager/aggregate'
      },
      {
        text: '葬儀用一覧データ',
        disabled: true
      }
    ],
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    isNoData: 0,
    isWait: 1,
    projectNameObj: {},
    projectItems: [],
    groupId: 0,
    group: '指定なし',
    companyItem: {},
    beginDateMenu2: false,
    endDateMenu2: false,
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDate2: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDate2: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    worktimeRecordItems: []
  }),
  computed: {
    processedItems () {
      let pItems1 = []
      let pItems = []

      pItems1 = this.soulProcessItems(this.worktimeRecordItems, this.projectNameObj, this.worktimeItems, this.beginDate, this.endDate, this.staff)

      pItems1.forEach((item) => {
        if (item.date >= this.beginDate) {
          pItems.push(item)
        }
      })

      let items = []

      pItems = pItems.concat(items).sort((a, b) => {
        if (a.date > b.date) {
          return 1
        } else {
          return -1
        }
      })

      pItems1.forEach((item) => {
        if (!item.date) {
          pItems.push(item)
        }
      })

      return pItems
    }
  },
  watch: {
    processedItems () {
      if (this.processedItems.length === 0) {
        this.isNoData = 1
      } else {
        this.isNoData = 0
      }
    }
  },
  mounted () {
    this.getStaff()
    this.getProject()
    this.getCompany()
    this.getWorktimeRecord()
    this.getWorktime()

    let loaded = false
    const scriptTags = Array.from(document.getElementsByTagName('script'))
    scriptTags.forEach((tag) => {
      if (/pdfmake/.test(tag.src)) {
        loaded = true
      }
    })
    if (loaded === false) {
      let pdfMakeScript = document.createElement('script')
      pdfMakeScript.src = '/vue/pdfmake/pdfmake.min.js'
      document.body.appendChild(pdfMakeScript)
      let vfsFontsScript = document.createElement('script')
      vfsFontsScript.src = '/vue/pdfmake/vfs_fonts.js'
      document.body.appendChild(vfsFontsScript)
    }
  },
  methods: {
    getStaff () {
      axios.get('/api/manager/staff')
        .then((res) => {
          this.staffItems = res.data
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/manager')
          } else {
            alert('データエラー')
          }
        })
    },
    printPDF: function (content, orientation) {
      window.pdfMake.fonts = {
        GenShin: {
          normal: 'GenShinGothic-P-Regular.ttf',
          bold: 'GenShinGothic-P-Regular.ttf',
          italics: 'GenShinGothic-P-Regular.ttf',
          bolditalics: 'GenShinGothic-P-Regular.ttf'
        }
      }
      window.pdfMake.createPdf({
        pageOrientation: orientation,
        pageMargins: [22, 5, 22, 5],
        content: content,
        styles: {
          th: {
            fillColor: '#eeeeee',
            alignment: 'left'
          },
          marginTop10: {
            margin: [0, 10, 0, 0]
          }
        },
        defaultStyle: {
          font: 'GenShin',
          fontSize: 8
        }
      }).open()
    },
    layoutTable () {
      return {
        hLineWidth: function () {
          return 0.2
        },
        vLineWidth: function () {
          return 0.2
        }
      }
    },
    printTable () {
      const items = []
      const staffNameItems = []
      const afterFromSetContentItems = []

      for (let i = 0; this.staffItems.length > i; i++) {
        this.staff = this.staffItems[i].name

        items[i] = []
        staffNameItems.push(this.staff)

        this.processedItems.forEach((item) => {
          let nonWorkTime = ''
          let wakeWorkTime = ''

          if (item.non_work_time !== 0) {
            nonWorkTime = item.non_work_time
          }
          if (item.wake_work_time !== 0) {
            wakeWorkTime = item.wake_work_time
          }
          if (i % 5 === 0) {
            items[i].push([
              { text: item.date, alignment: 'center' },
              item.begin_time,
              item.end_time,
              nonWorkTime,
              wakeWorkTime
            ])
          } else {
            let beginTime = ''
            let endTime = ''

            if (!item.begin_time) {
              beginTime = { text: '', margin: [0, 5.58] }
            } else {
              beginTime = item.begin_time
            }

            if (!item.end_time) {
              endTime = { text: '', margin: [0, 5.58] }
            } else {
              endTime = item.end_time
            }

            items[i].push([
              beginTime,
              endTime,
              nonWorkTime,
              wakeWorkTime
            ])
          }
        })
      }

      for (let j = 0; items.length > j; j++) {
        if (j % 5 === 0) {
          afterFromSetContentItems.push({
            columns: [
              {
                style: 'marginTop10',
                layout: this.layoutTable(),
                fontSize: 8,
                width: 'auto',
                alignment: 'center',
                table: {
                  widths: [
                    65, 28, 28, 28, 28
                  ],
                  body: [
                    [
                      { text: '日付', style: 'th', alignment: 'center', rowSpan: 2, margin: [0, 12] },
                      { text: staffNameItems[j], style: 'td', alignment: 'center', colSpan: 4, margin: [0, 2] },
                      { text: '' }, { text: '' }, { text: '' }
                    ],
                    [
                      {},
                      { text: '開始', style: 'th', alignment: 'center', margin: [0, 2] },
                      { text: '終了', style: 'th', alignment: 'center', margin: [0, 2] },
                      { text: '非作業', style: 'th', alignment: 'center', margin: [0, 2] },
                      { text: '通夜', style: 'th', alignment: 'center', margin: [0, 2] }
                    ],
                    ...items[j]
                  ]
                }
              }
            ]
          })
        } else if (j % 4 !== 0) {
          let jj = 0

          jj = parseInt(j / 5)

          afterFromSetContentItems[jj].columns.push({
            style: 'marginTop10',
            layout: this.layoutTable(),
            fontSize: 8,
            width: 'auto',
            alignment: 'center',
            table: {
              widths: [
                28, 28, 28, 28
              ],
              body: [
                [
                  { text: staffNameItems[j], style: 'td', alignment: 'center', colSpan: 4, margin: [0, 2] },
                  { text: '' }, { text: '' }, { text: '' }
                ],
                [
                  { text: '開始', style: 'th', alignment: 'center', margin: [0, 2.3] },
                  { text: '終了', style: 'th', alignment: 'center', margin: [0, 2.3] },
                  { text: '非作業', style: 'th', alignment: 'center', margin: [0, 2.3] },
                  { text: '通夜', style: 'th', alignment: 'center', margin: [0, 2.3] }
                ],
                ...items[j]
              ]
            }
          })
        } else {
          let jj2 = 0

          jj2 = parseInt(j / 5)

          afterFromSetContentItems[jj2].columns.push({
            style: 'marginTop10',
            layout: this.layoutTable(),
            fontSize: 8,
            width: 'auto',
            alignment: 'center',
            pageBreak: 'after',
            table: {
              widths: [
                28, 28, 28, 28
              ],
              body: [
                [
                  { text: staffNameItems[j], style: 'td', alignment: 'center', colSpan: 4, margin: [0, 2] },
                  { text: '' }, { text: '' }, { text: '' }
                ],
                [
                  { text: '開始', style: 'th', alignment: 'center', margin: [0, 2.3] },
                  { text: '終了', style: 'th', alignment: 'center', margin: [0, 2.3] },
                  { text: '非作業', style: 'th', alignment: 'center', margin: [0, 2.3] },
                  { text: '通夜', style: 'th', alignment: 'center', margin: [0, 2.3] }
                ],
                ...items[j]
              ]
            }
          })
        }
      }

      const content = [
        {
          text: '出 勤 簿',
          fontSize: 13,
          alignment: 'center'
        },
        {
          text: this.beginDate + ' 〜 ' + this.endDate,
          fontSize: 12,
          alignment: 'right'
        }
      ]

      for (let h = 0; afterFromSetContentItems.length > h; h++) {
        content.push(afterFromSetContentItems[h])
      }

      this.printPDF(content, 'landscape')
    },
    outputWorkingLocation (items) {
      let workingLocationText = ''
      let count = 1

      items.forEach((item) => {
        workingLocationText += '(' + item.area + ', ' + item.denomination + ', ' + item.hall_name + ', ' + item.type + ')'

        if (items.length !== 1 && count !== items.length) {
          workingLocationText += '➜'
        }
        count++
      })
      return workingLocationText
    },
    getWorktime () {
      axios.get('/api/manager/specify-worktime?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.worktimeItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getWorktime]')
        })
    },
    aggregate () {
      this.beginDate = this.beginDate2
      this.endDate = this.endDate2
      this.getWorktimeRecord()
    },
    changeNotationToHourTime (minute) {
      if (minute === 'leave-application') {
        return ''
      } else if (minute === '') {
        return ''
      } else if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.projectItems = []
          this.projectNameObj = {}

          this.projectItems = res.data

          res.data.forEach((item) => {
            if (!this.projectNameObj[item.id]) {
              this.projectNameObj[item.id] = item.name
            }
          })
        })
        .catch(() => {
          alert('project取得エラー')
        })
    },
    mouseleave () {
      this.bg = ''
      this.bgg = ''
    },
    mouseover (processedItemsIdx, idx) {
      this.bgg = processedItemsIdx
      this.bg = String(processedItemsIdx) + String(idx)
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    add24Hours (leaveTime) {
      return Number(leaveTime.slice(0, 2)) + 24 + leaveTime.slice(2, 5)
    },
    convAtTime (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm')
    },
    convAtDate (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getWorktimeRecord () {
      this.worktimeRecordItems = []
      this.isWait = 1
      // 週40時間を超えた場合の時間外の計算のために、日付指定（開始）より6日前から取得するようにする
      // 表示は日付指定（開始）より表示されるように制御する
      let beginDate = moment(this.beginDate).subtract(6, 'd').format('YYYY-MM-DD')
      axios.get('/api/manager/worktime-record?begin_date=' + beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.worktimeRecordItems = res.data

          this.$nextTick(() => {
            this.isWait = 0
          })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    }
  }
}
</script>

<style scoped>
table.v-table tbody td {
  border-left: 1px solid rgba(199, 127, 127, 0.12) !important;
}
.aggregate-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  font-size: 15px;
}
.aggregate-th {
  font-weight: bold;
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>

<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.tbody::-webkit-scrollbar {
    display:none;
}
</style>
