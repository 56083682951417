<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items2" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout row wrap class="mt-2">
      <v-flex xs2 class="px-2">
        <v-select
          :items="yearItems"
          v-model="yearItem"
          label="基準年選択"
          suffix="年"
          @change="getLeaveApplicationManagement"
        />
      </v-flex>
      <v-flex xs2 class="px-2">
        <v-select
          :items="retireItems"
          v-model="isRetired"
          item-value="id"
          item-text="name"
          prepend-icon="business"
        ></v-select>
      </v-flex>
      <v-flex xs3 class="px-2">
        <GroupCombo :value.sync="group" :groupId="groupId" />
      </v-flex>
      <v-flex xs2 class="px-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" :isRetired="isRetired"/>
      </v-flex>
      <v-flex xs3 class="pl-2">
        <v-checkbox v-model="onlyOver10" label="付与10日以上のみ表示" color="primary" />
      </v-flex>

    </v-layout>
    <v-card class="ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >有給取得管理</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <div class="pb-2 pl-5" style="color: #888;">
        ※取得日数・・・基準日〜有給取得義務期日の間に取得した日数。
        （時間単位年休が含まれています。）<br>
        <!--
        ※基準日〜有給取得義務期日の間に前々年の残数がある場合は「残日数」が一致しません。
        「基準年選択」にて該当年をご確認ください。
        -->
      </div>
      <v-data-table :headers="headers" :items="items" hide-actions>
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td
            class="leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.name }}</td>
          <td
            class="leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.begin_date }}</td>
          <td
            class="leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.limitDate }}</td>
          <td v-if="props.item.attention === 'false'" class="leave-application-td"></td>
          <td v-else class="leave-application-td">
            <v-icon color="orange">error_outline</v-icon>
          </td>
          <td
            class="text-xs-center leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.countDays }}</td>
          <td
            class="text-xs-center leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.prevRemainDays }}</td>
          <td
            class="text-xs-center leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.leave_days }}</td>
          <!--
          <td
            class="text-xs-center leave-application-td"
            :style="{'font-weight': props.item.font_size}"
          >{{ props.item.remainDays }}
          </td>
          -->
          <td class="text-xs-center leave-application-td" :class="props.item.class_name">
            <v-icon color="primary" @click="showBook(props.item)">
              assignment
            </v-icon>
          </td>
        </template>
      </v-data-table>

      <v-layout row justify-center>
        <v-dialog v-model="bookDialog">
          <LeaveApplicationManagementBook
            :item="bookItem"
            v-on:close="closeBookDialog()"
          />
        </v-dialog>
      </v-layout>

    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import LeaveApplicationManagementBook from '@/components/LeaveApplicationManagementBook'

export default {
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo,
    LeaveApplicationManagementBook
  },
  data: () => ({
    isRetired: 0,
    retireItems: [{ id: 0, name: '在職' }, { id: 1, name: '退職' }, { id: 3, name: '指定なし' }],
    staff: '',
    staffAllItems: [],
    groupId: 0,
    group: '指定なし',
    onlyOver10: true,
    bookItem: null,
    bookDialog: false,
    items2: [
      {
        text: '休暇・申請',
        disabled: false,
        href: '/#/manager/leave-application'
      },
      {
        text: '有給取得管理',
        disabled: true
      }
    ],
    today: moment().tz('Asia/Tokyo').format('YYYY-MM-DD'),
    dialog: false,
    staffId: 0,
    yearItem: moment().format('YYYY'),
    yearItems: [],
    headers: [
      {
        text: '氏名',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header',
        width: '150'
      },
      {
        text: '基準日',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '有給取得義務期日',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '取得日数',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '前年度残数',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      {
        text: '今年度付与数',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      /*
      {
        text: '残日数',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      },
      */
      {
        text: '年休管理簿',
        value: 'name',
        align: 'center',
        sortable: false,
        class: 'leave-application-header'
      }
    ],
    leaveApplicationManagement: null
  }),
  mounted () {
    let currentYear = moment().year()
    for (let i = currentYear - 4; i <= currentYear + 12; i++) {
      this.yearItems.push(String(i))
    }
    axios.get('/api/manager/staff')
      .then((res) => {
        this.staffAllItems = res.data
        this.getLeaveApplicationManagement()
      })
      .catch((error) => {
        this.errorDecision(error, '/manager', '取得エラー[getStaff]')
      })

    let loaded = false
    const scriptTags = Array.from(document.getElementsByTagName('script'))
    scriptTags.forEach((tag) => {
      if (/pdfmake/.test(tag.src)) {
        loaded = true
      }
    })
    if (loaded === false) {
      let pdfMakeScript = document.createElement('script')
      pdfMakeScript.src = '/vue/pdfmake/pdfmake.min.js'
      document.body.appendChild(pdfMakeScript)
      let vfsFontsScript = document.createElement('script')
      vfsFontsScript.src = '/vue/pdfmake/vfs_fonts.js'
      document.body.appendChild(vfsFontsScript)
    }
  },
  computed: {
    staffItems () {
      const result = []
      this.staffAllItems.forEach((item) => {
        if (this.isRetired === 0) {
          if (item.is_retired !== 0) {
            return
          }
        }
        if (this.isRetired === 1) {
          if (item.is_retired !== 1) {
            return
          }
        }
        if (this.group !== '指定なし') {
          if (this.group !== '') {
            if (this.groupId !== item.group_id) {
              return
            }
          }
        }
        result.push(item)
      })
      return result
    },
    items () {
      const result = []
      if (this.leaveApplicationManagement === null) {
        return result
      }
      if (this.leaveApplicationManagement) {
        const staffMap = new Map()
        this.staffItems.forEach((item) => {
          if ((this.staff) && (this.staff !== '指定なし')) {
            if (!item.name.includes(this.staff)) {
              return
            }
          }
          staffMap.set(item.id, item)
        })

        const leaveGrantItems = this.leaveApplicationManagement.leave_grant_items
        const usedLeaveGrant = {}
        this.leaveApplicationManagement.used_leave_grant_items.forEach((item) => {
          if (!usedLeaveGrant[item.leave_grant_id]) {
            usedLeaveGrant[item.leave_grant_id] = []
          }
          usedLeaveGrant[item.leave_grant_id].push(item)
        })
        const leaveApplicationItems = this.leaveApplicationManagement.leave_application_items

        const targetYearLeaveGrantItems = []
        const prevStaffLeaveGrant = {}
        leaveGrantItems.forEach((grantItem) => {
          const matchedYear = grantItem.begin_date.match(/^(\d{4})/)
          if (matchedYear !== null) {
            if (parseInt(matchedYear[1]) === parseInt(this.yearItem)) {
              targetYearLeaveGrantItems.push(grantItem)
            } else if (parseInt(matchedYear[1]) < parseInt(this.yearItem)) {
              if (!prevStaffLeaveGrant[grantItem.staff_id]) {
                prevStaffLeaveGrant[grantItem.staff_id] = []
              }
              prevStaffLeaveGrant[grantItem.staff_id].push(grantItem)
            }
          }
        })

        const staffLeaveApplication = {}
        leaveApplicationItems.forEach((applicationItem) => {
          if (!staffLeaveApplication[applicationItem.staff_id]) {
            staffLeaveApplication[applicationItem.staff_id] = []
          }
          staffLeaveApplication[applicationItem.staff_id].push(applicationItem)
        })

        targetYearLeaveGrantItems.forEach((grantItem) => {
          if (staffMap.has(grantItem.staff_id) === false) {
            return
          }
          const item = { ...grantItem }
          item.name = staffMap.get(grantItem.staff_id).name
          item.limitDate =
            moment(grantItem.begin_date).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD')

          item.font_size = 'normal'
          if (this.today >= grantItem.begin_date && this.today <= grantItem.end_date) {
            item.font_size = 'bold'
          }

          item.bookItem = {
            beginDate: moment(item.begin_date, 'YYYY-MM-DD').format('YYYY/M/D'),
            staffName: staffMap.get(item.staff_id).name,
            countDays: 0,
            dayItems: []
          }

          // 期間中に何日取得したか。（この付与に対してではない）
          // 有給取得義務としては、時間単位年休は除外する必要があるが、ここでは含めて表示
          item.countDays = 0
          if (staffLeaveApplication[grantItem.staff_id]) {
            staffLeaveApplication[grantItem.staff_id].forEach((applicationItem) => {
              const applicationDate =
                moment(applicationItem.application_date).format('YYYY-MM-DD')
              if (applicationDate < grantItem.begin_date) {
                return
              }
              if (applicationDate > item.limitDate) {
                return
              }
              let days = 0
              if (applicationItem.category === 'am_paid_leave') {
                days = 0.5
              } else if (applicationItem.category === 'pm_paid_leave') {
                days = 0.5
              } else if (applicationItem.category === 'paid_leave') {
                days = 1
              } else if (applicationItem.category === 'time_paid_leave') {
                days = applicationItem.time_paid_leave / 10
              }
              item.countDays = parseFloat((item.countDays + days).toFixed(1))
              item.bookItem.dayItems.push(
                `${moment(applicationDate).format('YYYY/M/D')}<br />` +
                `(${moment(applicationDate).format('ddd')}) [${days}日]`
              )
            })
          }
          item.bookItem.countDays = item.countDays

          let today = moment(this.today, 'YYYY-MM-DD')
          const limitDate = moment(item.limitDate, 'YYYY-MM-DD')

          const monthDifference = (limitDate.diff(today, 'months'))

          item.attention = 'false'
          if ((5 - item.countDays) >= monthDifference && today < limitDate) {
            item.attention = 'true'
          }

          // 残日数は義務期間内の消化ではなく、全期間の消化を対象にしているので、
          // 上記を計算してもこの残日数と不一致の場合がある。
          item.remainDays = item.leave_days
          if (usedLeaveGrant[item.id]) {
            usedLeaveGrant[item.id].forEach((usedItem) => {
              item.remainDays =
                parseFloat((item.remainDays - usedItem.days).toFixed(1))
            })
          }

          item.prevRemainDays = 0
          if (prevStaffLeaveGrant[grantItem.staff_id]) {
            prevStaffLeaveGrant[grantItem.staff_id].forEach((prevGrantItem) => {
              item.prevRemainDays += prevGrantItem.leave_days
              if (usedLeaveGrant[prevGrantItem.id]) {
                usedLeaveGrant[prevGrantItem.id].forEach((usedItem) => {
                  item.prevRemainDays =
                    parseFloat((item.prevRemainDays - usedItem.days).toFixed(1))
                })
              }
            })
          }
          item.remainDays = parseFloat((item.remainDays + item.prevRemainDays).toFixed(1))

          if (this.onlyOver10) {
            // 有給休暇付与日数が10日未満のスタッフは有給休暇管理に表示させない
            if (item.leave_days >= 10) {
              result.push(item)
            }
          } else {
            result.push(item)
          }
        })
      }
      result.sort((a, b) => {
        if (a.staff_id > b.staff_id) {
          return 1
        } else {
          return -1
        }
      })
      return result
    }
  },
  methods: {
    showBook (item) {
      this.bookItem = item.bookItem
      this.bookDialog = true
    },
    closeBookDialog () {
      this.bookDialog = false
    },
    getLeaveApplicationManagement (staffId, dialog) {
      axios.get(`/api/manager/leave-application-management?year=${this.yearItem}`)
        .then((res) => {
          this.leaveApplicationManagement = res.data
        })
        .catch((err) => {
          console.log(err)
          alert('有給休暇数取得エラー[M]')
        })
    }
  }
}
</script>
