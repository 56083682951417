<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="dialog"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="yearMonth"
              label="年月選択"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="yearMonth"
            locale="ja-jp"
            type="month"
            :day-format="date => new Date(date).getDate()"
            @input="dialog = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs4 class="pa-2">
        <GroupCombo :value.sync="group" :groupId="groupId"/>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId"/>
      </v-flex>
      <v-spacer />
      <!-- <v-btn :disabled="closedMonthlyDateItems.length > 1" flat outline color="#6ac67b" class="mb-2" @click="pdf()">
        印刷する
        <v-icon right dark>print</v-icon>
      </v-btn> -->
      <v-btn v-if="companyId === 7" color="#6ac67b" dark class="mb-2" @click="csvOutputDialog = true">CSV出力</v-btn>
      <v-dialog
        v-model="csvOutputDialog"
        persistent
        max-width="50%"
        scrollable
      >
        <v-card>
          <v-card-title>
            <span class="headline">CSV出力確認</span>
          </v-card-title>
            {{ selectYear }} 年{{ selectMonth }} 月のデータをダウンロードする。
          <v-card-text>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat color="grey lighten-1" @click="csvOutputDialog = false">Cancel</v-btn>
            <v-btn @click="downloadCSV" color="primary">
              <b>CSV ダウンロード</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">
          締め済み月次データ<span class="title">/{{ selectYear }}年{{ selectMonth }}月度</span>
          <span v-if="closedMonthlyDateItems.length !== 0" class="caption">（締め日：{{ closedMonthlyDateItems[0].closed_date }}期間：{{ closedMonthlyDateItems[0].begin_date }} ~ {{ closedMonthlyDateItems[0].end_date }}）</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="red accent-1"
          dark
          class="mb-2"
          :disabled="closedMonthlyDateItems.length === 0"
          @click="deleteClosedMonthDate()"
        >
          <v-icon>delete</v-icon>
        </v-btn>
        </v-toolbar>
      <div style="display: block">
        <v-layout row wrap class="timetable automatic-aggregate-header">
          <v-flex xs2 class="pa-3" style="font-weight: bold; padding-top: 2em !important" text-xs-center>
            氏名
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                出勤<br>日数
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                休日<br>出勤<br>日数
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                遅刻<br>回数
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                早退<br>回数
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                有休<br>日数
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                遅刻<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                早退<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                休憩<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 2em !important">
                時間外
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                深夜<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                法定<br>時間内
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th" style="padding-top: 1.3em !important">
                法定<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                休日<br>労働<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                休日<br>深夜<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 automatic-aggregate-th">
                労働<br>時間<br>合計
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
        </v-layout>
        <v-alert :value="closedMonthlyDateItems.length == 0" color="error" icon="warning" outline>
          データがありません
        </v-alert>
      </div>
      <div class="tbody" style="font-size: 15px">
        <v-layout v-for="(item, idx) in closedMonthlyDateItems" :key="item.name" row wrap class="timetable">
          <v-flex
            xs2
            class="pa-2 automatic-aggregate-td"
            v-on:mouseover="mouseover(idx)"
            v-on:mouseleave="mouseleave"
            :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
          >
            <span class="subheading">{{ item.name }}</span>
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <v-flex
                xs1
                :key="`name-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.work_time }}
              </v-flex>
              <v-flex
                xs1
                :key="`name2-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.holiday_worktime }}
              </v-flex>
              <v-flex
                xs1
                :key="`attendance-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.is_tardiness }}
              </v-flex>
              <v-flex
                xs1
                :key="`leave-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.is_early_withdrawal }}
              </v-flex>
              <v-flex
                xs1
                :key="`restHours-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.leave_application }}
              </v-flex>
              <v-flex
                xs1
                :key="`workHours-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.tardiness_time | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.early_withdrawal_time | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours2-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.rest_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours300-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.overtime_workday_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours4-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.overtime_workday_midnight_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours3-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.legal_overtime_working_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours30-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.non_statutory_working_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours5-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.holiday_work_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours6-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.overtime_holiday_workday_midnight_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex
                xs1
                :key="`offHours7-${idx}`"
                class="pa-2 automatic-aggregate-td"
                v-on:mouseover="mouseover(idx)"
                v-on:mouseleave="mouseleave"
                :style="{'background-color': bg === idx ? '#eeeeee' : ''}"
              >
                {{ item.work_minute | changeNotationToHourTime }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="idx !== closedMonthlyDateItems.length - 1 || idx === closedMonthlyDateItems.length - 1"
            xs12
          >
            <v-divider />
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import Encoding from 'encoding-japanese'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import StaffCombo from '@/components/StaffCombo'
import GroupCombo from '@/components/GroupCombo'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ClosedMonthlyPdf from '@/mixins/ClosedMonthlyPdf'

export default {
  mixins: [validationMixin, ClosedMonthlyPdf],
  components: {
    MyToolbar,
    StaffCombo,
    GroupCombo
  },
  validations: {
    selectYear: { required },
    selectMonth: { required }
  },
  data: () => ({
    staff: '指定なし',
    staffItems: [],
    groupId: 0,
    groupNameObj: {},
    group: '指定なし',
    companyId: 0,
    beginDateMenu2: false,
    endDateMenu2: false,
    csvOutputDialog: false,
    csvBeginDate: moment().date(16).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    csvEndDate: moment().add(1, 'months').date(15).tz('Asia/Tokyo').format('YYYY-MM-DD'),
    dialog: false,
    closedMonthlyDateItems: [],
    items: [
      {
        text: '集計データ',
        disabled: false,
        href: '/#/manager/automatic-aggregate'
      },
      {
        text: '締め済み月次データ',
        disabled: true
      }
    ],
    yearMonth: moment().tz('Asia/Tokyo').format('YYYY-MM'),
    selectYear: '',
    selectMonth: '',
    monthItems: [],
    yearItems: [],
    bg: ''
  }),
  filters: {
    changeNotationToHourTime: function (minute) {
      if (minute === 'leave-application') {
        return ''
      } else if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    }
  },
  watch: {
    yearMonth () {
      this.selectYear = this.yearMonth.substr(0, 4)
      this.selectMonth = this.yearMonth.substr(5, 2)
      this.getClosedMonthlyData()
    },
    staff () {
      this.selectYear = this.yearMonth.substr(0, 4)
      this.selectMonth = this.yearMonth.substr(5, 2)
      this.getClosedMonthlyData()
    },
    groupId () {
      this.selectYear = this.yearMonth.substr(0, 4)
      this.selectMonth = this.yearMonth.substr(5, 2)
      this.getClosedMonthlyData()
    }
  },
  mounted () {
    this.selectYear = this.yearMonth.substr(0, 4)
    this.selectMonth = this.yearMonth.substr(5, 2)
    this.getClosedMonthlyData()

    let loaded = false
    const scriptTags = Array.from(document.getElementsByTagName('script'))
    scriptTags.forEach((tag) => {
      if (/pdfmake/.test(tag.src)) {
        loaded = true
      }
    })
    if (loaded === false) {
      let pdfMakeScript = document.createElement('script')
      pdfMakeScript.src = '/vue/pdfmake/pdfmake.min.js'
      document.body.appendChild(pdfMakeScript)
      let vfsFontsScript = document.createElement('script')
      vfsFontsScript.src = '/vue/pdfmake/vfs_fonts.js'
      document.body.appendChild(vfsFontsScript)
    }
  },
  methods: {
    changeNotationToHourTime2 (minute) {
      if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    },
    downloadCSV () {
      let items2 = []

      // axios.get('/api/manager/leave-application?begin_date=' + this.csvBeginDate + '&end_date=' + this.csvEndDate)
      //   .then((res) => {
      //     res.data.forEach((leaveApplicationData) => {
      //       if (leaveApplicationData.is_approved === 1) {
      //         let leaveApplicationDataObj = {
      //           '従業員ID': leaveApplicationData.yayoi_code,
      //           '日付': moment(leaveApplicationData.application_date).format('YYYY/MM/DD'),
      //           '出勤時刻': '',
      //           '退勤時刻': '',
      //           '普外時間': '',
      //           '残外時間': '',
      //           '事由': this.leaveApplicationDataItems[leaveApplicationData.category]
      //         }
      //         items2.push(leaveApplicationDataObj)
      //       }
      //     })
      //     axios.get('/api/manager/specified-worktime?begin_date=' + this.csvBeginDate + '&end_date=' + this.csvEndDate)
      //       .then((res) => {
      //         let items = this.processItems(res.data, '')

      //         items.forEach((dayObj) => {
      //           dayObj.staffItems.forEach((staffItem) => {
      //             let staffObj = {
      //               '従業員ID': staffItem.yayoi_code,
      //               '日付': moment(dayObj.date).format('YYYY/MM/DD'),
      //               '出勤時刻': staffItem.attendance,
      //               '退勤時刻': staffItem.leave,
      //               '普外時間': '00:00',
      //               '残外時間': '00:00',
      //               '事由': '設定なし'
      //             }
      //             items2.push(staffObj)
      //           })
      //         })
      //         const csvData = Papa.unparse(items2)
      //         const unicodeList = []
      //         for (let i = 0; i < csvData.length; i += 1) {
      //           unicodeList.push(csvData.charCodeAt(i))
      //         }
      //         const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode')
      //         const uInt8List = new Uint8Array(shiftJisCodeList)
      //         const writeData = new Blob([uInt8List], { type: 'text/csv' })
      //         saveAs(writeData, 'itc-attendance.csv')
      //         this.csvOutputDialog = false
      //       })
      //       .catch(() => {
      //         alert('データ取得エラー[csvOutput]')
      //       })
      //   })
      //   .catch(() => {
      //     alert('取得エラー')
      //   })
      let csvText = [
        '従業員コード',
        '社員氏名',
        '出勤日数',
        '休日出勤日数',
        '有給日数',
        '特別休暇日数',
        '欠勤日数',
        '就労時間',
        '普通残業',
        '深夜残業',
        '法定休日勤務時間',
        '法定内残業',
        '遅早回数',
        '遅早時間'
      ]
      items2.push(csvText)
      this.closedMonthlyDateItems.forEach((item) => {
        let staffItems = [
          item.yayoi_code,
          item.name,
          item.work_time,
          item.holiday_worktime,
          item.leave_application,
          '',
          '',
          this.changeNotationToHourTime2(item.work_minute),
          this.changeNotationToHourTime2(item.non_statutory_working_minutes),
          this.changeNotationToHourTime2(item.overtime_workday_midnight_minutes),
          this.changeNotationToHourTime2(item.holiday_work_minutes),
          this.changeNotationToHourTime2(item.legal_overtime_working_minutes),
          item.is_tardiness + item.is_early_withdrawal,
          this.changeNotationToHourTime2(item.tardiness_time) + this.changeNotationToHourTime2(item.early_withdrawal_time)
        ]
        items2.push(staffItems)
      })
      const csvData = Papa.unparse(items2)
      const unicodeList = []
      for (let i = 0; i < csvData.length; i += 1) {
        unicodeList.push(csvData.charCodeAt(i))
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode')
      const uInt8List = new Uint8Array(shiftJisCodeList)
      const writeData = new Blob([uInt8List], { type: 'text/csv' })
      saveAs(writeData, 'itc-attendance.csv')
      this.csvOutputDialog = false
    },
    deleteClosedMonthDate () {
      if (confirm(this.selectYear + '年' + this.selectMonth + '月度' + 'の締め済み月次データを削除します。')) {
        let selectMonth = ''
        if (Number(this.selectMonth) <= 9) {
          selectMonth = this.selectMonth.replace(/0/g, '')
        } else {
          selectMonth = this.selectMonth
        }
        let yearMonth = this.selectYear + '-' + selectMonth

        axios.delete('/api/manager/close-monthly/' + yearMonth)
          .then(() => {
            this.selectYear = this.yearMonth.substr(0, 4)
            this.selectMonth = this.yearMonth.substr(5, 2)
            this.getClosedMonthlyData()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    open () {
      this.$v.$reset()
      this.dialog = true
    },
    cancel () {
      this.$v.$reset()
      this.selectYear = ''
      this.selectMonth = ''
      this.dialog = false
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.selectYear = ''
        this.selectMonth = ''
        this.dialog = false
      }
    },
    monthArrayCreation () {
      for (let i = 1; i <= 12; i++) {
        this.monthItems.push(String(i))
      }
    },
    yearArrayCreation () {
      let currentYear = moment().year()
      for (let i = currentYear - 3; i <= currentYear; i++) {
        this.yearItems.push(String(i))
      }
    },
    mouseleave () {
      this.bg = ''
    },
    mouseover (idx) {
      this.bg = idx
    },
    getClosedMonthlyData () {
      this.closedMonthlyDateItems = []
      let selectMonth = ''

      if (Number(this.selectMonth) <= 9) {
        selectMonth = this.selectMonth.replace(/0/g, '')
      } else {
        selectMonth = this.selectMonth
      }
      let yearMonth = this.selectYear + '-' + selectMonth
      axios.get('/api/manager/close-monthly?year_month=' + yearMonth)
        .then((res) => {
          let items = []
          this.companyId = res.data['0'].company_id

          res.data.forEach((item) => {
            if (this.groupId !== 0 && item.group_id !== this.groupId) {
              return
            }
            if (this.staff !== '指定なし' && item.name !== this.staff) {
              return
            }
            items.push(item)
          })
          this.closedMonthlyDateItems = items
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    }
  }
}
</script>

<style>
.automatic-aggregate-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
}
.automatic-aggregate-th {
  font-weight: bold;
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.automatic-aggregate-td {
  border-right: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>

<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.tbody::-webkit-scrollbar {
    display:none;
}
</style>
